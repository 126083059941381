
import { defineComponent, reactive, onMounted, toRefs, ref, watch , PropType} from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import moment from "moment";
import { ContentLoader } from 'vue-content-loader';
import ApexChart from "apexcharts";
import useEmitter from '@/composables/Emmiter';
import { IGrafico } from "@/models/OficinaDigitalRelatorioCompletoModel";
import { graficoHistorico } from "@/services/OficinaDigital";

export default defineComponent({
    name: "historico-faturamento-card",
    props: {
        metas: Object,
        loading: Boolean,
        dataFaturamentoTotal: Array,
        faturamento: {
            type: Array as PropType<Array<IGrafico>>,
            required: true
        },
        periodoTitle: String,
        animate: Boolean
    },
    components: {
        ContentLoader
    },

    setup(props)  {
        const color = getCSSVariableValue("cor_indicadores_1");
        const style =  ref(" height: 200px;top: 20px;")      
        const data = reactive({
          Indicadores: {
            valortotalProdutosGeral: 0,
            valorRevisoes: 0,
            valorticketMedioGeral: 0,
            valorTicketMedioDistribuidor: 0,
            somaProdutosDistribuidor: 0,
          },
        });
        const verificadormensal = ref(props.faturamento);
        const animateGraficos = ref(props.animate)
        const series:any = ref([]);
        const titleDataHistorico:any = ref();
        const auxPDFShowDiario = ref(false);
        const emitter = useEmitter();
        const filter = reactive({
			codConcessionaria : '',
			codConsultor : "",
			filtersData: "",
			placa: "",
		})
        const noDataChartHisty = ref(false)
        const graficoHistoricoMensal  = reactive({
            showGrafico: true,
            loading: false
        })
        const requestHistorico = ref(true)

        watch(() => props.faturamento, () => {
            animateGraficos.value = props.animate
            if(props.faturamento){
                verificadormensal.value = props.faturamento;
                const geragrafico = props.faturamento
                series.value = geragrafico;              

                geraGrafico(props.faturamento);     
                
                if(props.faturamento.length == 0){
                    getGraficoHistorico()
                }
                
                if(props.faturamento.length > 0){
                    const refElement = document.getElementById("diario-pane");
                    refElement?.click();
                    const refElementHistorico = document.getElementById("historico-pane");
                    refElementHistorico?.classList.remove("active");
                } else {
                    const refElement = document.getElementById("historico-pane");
                    refElement?.click();
                }

                if(geragrafico.length > 0){
                    auxPDFShowDiario.value = true;
                } else {
                    auxPDFShowDiario.value = false;
                    const refNavConsultor = document.getElementById("historico-pane");

                    setTimeout(() => {
                        refNavConsultor?.click();
                    }, 500)
                }

                emiteMudancaClickPDF();
            }
       });

		emitter.on('filter-relatorio-completo', (emiter) => {
            titleDataHistorico.value = ''
            Object.assign(filter,emiter)
            requestHistorico.value = true
        })

       async function getGraficoHistorico(){
            if(requestHistorico.value){
                titleDataHistorico.value = ''
                graficoHistoricoMensal.loading = true
                graficoHistoricoMensal.showGrafico = true
                noDataChartHisty.value = false    
                const { codConcessionaria, codConsultor,filtersData,placa } = filter
                const response = await graficoHistorico(codConcessionaria,filtersData,codConsultor,placa)
                noDataChartHisty.value =  response.length > 0 ? false : true                           
                graficoHistoricoMensal.loading = false                  
                setTimeout(()=>{
                    faturamentoDosMeses(response);
                },200)
                graficoHistoricoMensal.showGrafico = false
                titleDataHistorico.value = ajustaData(response)
            }    
            
            requestHistorico.value = false
       }

        function geraGrafico(response){
            seriesIndicadores.value[0].data = response.map((categoria) =>  {  
                return [moment.utc(`${categoria.ano}-${categoria.mes}-${categoria.dia} 12:00:00`).unix(), categoria.serie];            
            });
        
            if (seriesIndicadores.value[0].data.length === 1) {
                const refSerieIndicadorUnix = seriesIndicadores.value[0].data[0][0] + 10;
                const refSerieIndicadorData = seriesIndicadores.value[0].data[0][1];  
                seriesIndicadores.value[0].data.push([refSerieIndicadorUnix, refSerieIndicadorData]);
            }
        };
        
        let chartInstance;

        function emiteMudancaClickPDF(){
            emitter.emit("filtrar-od-finalizados-pdf-show", auxPDFShowDiario.value);
        }

        function ajustaLegenda(){
            if(hideApex.value == true && props.faturamento.length == 1){
                return "Faturamento por mês";
            }
            if(hideApex.value == true && props.faturamento.length > 1){
                return "Faturamento por dia";
            } else {
                return "Faturamento por mês"
            }
        }

        onMounted(async () => {
            const options = geraOptions([]);
            const series = geraSeries([]);

            chartInstance = new ApexChart(
                document.querySelector("#graficofaturamentomensal"),
                {
                    ...options,
                    series,
                }
            );
            chartInstance.render();

            emiteMudancaClickPDF();
        });

        
        function geraOptions(categories) {
            const options = {
                chart: {    
                    offsetY: -30,
                    fontFamily: "inherit",
                    type: "bar",
                    width: 750,
                    height: 225,
                    toolbar: {
                        show: false,
                    }, 
                    zoom: {
                        enabled: false,
                    },
                    sparkline: {
                        enabled: false,
                    },
                    dropShadow: {
                        enabled: false,
                    },
                },
            grid: {
                show: true,
                clipMarkers: true,
                padding: {
                    left: 30,
                    right: 30,
                    top: -10,         
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    columnWidth: "20%",
                    endingShape: 'rounded',
                    dataLabels: {
                    position: 'top',
                        }
                    },
                },
            colors:['var(--cor_indicadores_1)'],
            dataLabels: {            
                offsetY: -20,
                enabled: true,
                formatter: function (value) {
                    const valueFormatter = value && !isNaN(value) ? value : 0;
                    return  'R$' + Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(valueFormatter); ;
                },
                style: {
                    fontSize: '10px',
                    colors: ['var(--cor_texto_1)'],
                    },
                distributed: true,
                background : false,
            },
            fill: { },
                    xaxis: {
                        categories,
                        lines: { 
                            show: true  //or just here to disable only y axis
                        },      
                        labels: {
                            show: true,
                            style: {
                                colors: ['var(--cor_texto_1)'],
                                fontSize: "10px",
                            },		
                            rotateAlways: true,
                        },
                    },
                    yaxis: {     
                        axisTicks: {
                            show: true, 
                        },
                        lines: { 
                            show: true  //or just here to disable only y axis
                            },      
                        labels: {
                            formatter: function (val) {
                                return  'R$' + Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(val);
                            },
                            show: false,
                            style: {
                                colors: ['var(--cor_indicadores_1)'],
                                fontSize: "7px",
                            },	
                        },
                    },
                    states: {
                        normal: {
                            filter: {
                                type: "none",
                                value: 0,
                            },
                        },
                        hover: {
                            filter: {
                                type: "none",
                                value: 0,
                            },
                        },
                        active: {
                            allowMultipleDataPointsSelection: true,
                            filter: {
                                type: "none",
                                value: 0,
                            },
                        },
                    },
                    tooltip: {
                        style: {
                            background:['var(--cor_indicadores_2)'],
                            colors: ['var(--cor_indicadores_2)'],
                            fontSize: "12px",
                        },
                        
                        y: {
                            formatter: function (val) {
                                return  'R$' + Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(val);
                            },
                        },
                        marker: {
                            show: true,
                        },
                    },
                    markers: {
                        colors: [color],
                        strokeColor: ['var(--cor_indicadores_1)'],
                        strokeWidth: 5,
                    },
        }

      return options;
        }  

        function geraSeries(grafico2) {
            const seriesgraficomes = grafico2.map(serieMes => serieMes.serie);
            
            const series = [
                {
                    name: "Faturamento mensal",
                    data: seriesgraficomes
                },
            ];

            return series;  
        }

        const chartIndicadores = ref({
            chart: {    
                offsetY: -25,
                fontFamily: "inherit",
                type: "area",
                width: 400,
				height: 225,
                toolbar: {
                    show: false,
                }, 
                zoom: {
                    enabled: false,
                },
                sparkline: {
                    enabled: false,
                },
                dropShadow: {
                    enabled: false,
                },
            },
            grid: {
		    	show: true,
                clipMarkers: true,
                padding: {
                	left: 30,
                    right: 30,
                    top: -10,
                 
                }
            },
        	plotOptions: {},
            legend: {
                show: false,
            },
            colors:['var(--cor_indicadores_1)'],
            dataLabels: {            
                offsetY: -5,
                enabled: true,
                formatter: function (val) {
					const valueFormatter = val && !isNaN(val) ? val : 0;
					return  'R$' + Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(valueFormatter); ;
				},
                style: {
                    fontSize: '10px',
                    colors: ['var(--cor_texto_1)'],
               },
                distributed: true,
                background : false,
            },
            fill: {
                colors: ['var(--cor_indicadores_1)'],
                type: "gradient",
                gradient: {
                    shade: 'dark',
                    type: "vertical",
                    shadeIntensity: 0,
                    colors: ['var(--cor_indicadores_1)'],
                    gradientToColors: ['var(--cor_indicadores_1)'], // optional, if not defined - uses the shades of same color in series
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 0,
                    stops: [0, 80, 100],
                    colorStops: []
                }
            },
            stroke: {
                curve: "smooth",
                show: true,
                width: 3,
                colors: ['var(--cor_indicadores_1)'],
            },
            xaxis: {    
                axisTicks: {
					show: true, 
                },
                lines: { 
                    show: true  //or just here to disable only y axis
                    },      
                labels: {
                    borderRadius: 200,
                     type: "datetime",
                    formatter: function (val) {
                        //return val;
                        moment.locale('pt-br'); 
                        return moment.unix(val).format("DD/MM/YYYY")
                    },
                    showDuplicates: false,
                    show: true,
                    style: {
						colors: ['var(--cor_texto_1)'],
                        fontSize: "10px",
					},		
                    rotateAlways: true,
                },
            },
            yaxis: {     
                axisTicks: {
					show: true, 
                },
                lines: { 
                    show: true  //or just here to disable only y axis
                    },      
                labels: {
                    formatter: function (val) {
					    return  'R$' + Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(val);
					},
                    show: false,
                    style: {
						colors: ['var(--cor_indicadores_1)'],
                        fontSize: "7px",
					},	
                },
            },
            states: {
                normal: {
                    filter: {
                        type: "none",
                        value: 0,
                    },
                },
                hover: {
                    filter: {
                        type: "none",
                        value: 0,
                    },
                },
                active: {
                    allowMultipleDataPointsSelection: true,
                    filter: {
                        type: "none",
                        value: 0,
                    },
                },
            },
            tooltip: {
                style: {
                    background:['var(--cor_indicadores_2)'],
                    colors: ['var(--cor_indicadores_2)'],
                    fontSize: "12px",
                },
				
				y: {
					formatter: function (val) {
						return  'R$' + Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(val);
					},
				},
                marker: {
                    show: true,
                },
            },
            markers: {
                colors: [color],
                strokeColor: ['var(--cor_indicadores_1)'],
                strokeWidth: 5,
            },
        });

        async function faturamentoDosMeses(refSeries) {
            
            const series = geraSeries(refSeries);

            const categories = refSeries.map((categoria) =>  {
                const auxData = moment(`${categoria.ano}/${categoria.mes}`, "YYYY/M").format("MM/YYYY");
                return auxData;
            });
            const options = geraOptions(categories);
            chartInstance.updateOptions(options); 
            chartInstance.updateSeries(series);            
        }

        function ajustaData(array){            
            if (array.length === 0) {
                return " ";
            }

            const primeiroMes = new Date(array[0].ano, array[0].mes - 1);
            const ultimoMes = new Date(array[array.length - 1].ano, array[array.length - 1].mes - 1);

            const meses = [
                "janeiro", "fevereiro", "março", "abril", "maio", "junho",
                "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"
            ];

            const periodo = `Entre ${meses[primeiroMes.getMonth()]} de ${primeiroMes.getFullYear()} e ${meses[ultimoMes.getMonth()]} de ${ultimoMes.getFullYear()}`;

            return periodo;
        }

        const hideApex = ref(true);

        function renderGrafico(){
            hideApex.value = false;          
            resizeWithTime(100);
        }

        function resizeWithTime(time = 100){
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'))
            }, time)
        }

        function someGrafico(){
            hideApex.value = true;
            resizeWithTime(100);
        }

        const categoriesIndicadores = ref([{
			type: 'string',
			categories: ['', '', '', '', '', '']
		}]);

        const categoriesIndicadores2 = ref([{
			type: 'bar',
			categories: ['', '', '', '', '', '']
		}]);

        const seriesIndicadores = ref([{
            name: 'Faturamento',
            data: [[0, 0], [0, 0], [0,0], [0,0], [0,0], [0,0]],
        }]);

        const totalRevisoes = ref(0);
          
        return {
            chartIndicadores,
            seriesIndicadores,
            series,
            titleDataHistorico,
            totalRevisoes,
            categoriesIndicadores,
            categoriesIndicadores2,
            ...toRefs(data),
            style,
            hideApex,
            renderGrafico,
            someGrafico,
            ajustaLegenda,
            resizeWithTime,
            auxPDFShowDiario,
            emiteMudancaClickPDF,
            verificadormensal,
            ajustaData,
            getGraficoHistorico,
            graficoHistoricoMensal,
            noDataChartHisty                 
        }
   },
});
