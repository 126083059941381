import moment from "moment";
import { useStore } from "vuex";

export default function () {
    const store = useStore();
    let FileSaver = require('file-saver');


    function geraFileName(concessionariaName, routeName) {
        const dataAtual = moment().format("YYYY-MM-DD");
        const concessionariaNameTratado = concessionariaName.replaceAll(" ", "_");
        return `${routeName}${dataAtual}_${concessionariaNameTratado}.pdf`
    }

    function viewPdfNewTab(base64str, routeName = "", hideConcessionariaName = false){
        const concessionariaName = hideConcessionariaName ? "" : store.getters.concessionariaSelectedInfo.nomeFantasia+"_"+store.getters.concessionariaSelectedInfo.codAtria.replace("/","");
        viewPdf(base64str, geraFileName(concessionariaName, routeName));  
    }

    function exportBlob(base64str, routeName = "", hideConcessionariaName = false){
        const concessionariaName = hideConcessionariaName ? "" : store.getters.concessionariaSelectedInfo.nomeFantasia;
        geraBlob(base64str, geraFileName(concessionariaName, routeName));  
       
        // Descomentar para abrir o arquivo
        // const url = geraBlob(base64str);
        // window.open(url);
    }

    function viewPdf(base64str, fileName = "export-pdf.pdf"){
        // base64 string
        // decode base64 string, remove space for IE compatibility
        let binary = atob(base64str.replace(/\s/g, ''));
        let len = binary.length;
        let buffer = new ArrayBuffer(len);
        let view = new Uint8Array(buffer);
        for (let i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }
  
        // create the blob object with content-type "application/pdf"       
        let blob = new Blob( [view], { type: "application/pdf;charset=utf-8;" });
        
        let url = URL.createObjectURL(blob);
                
        window.open(url, '_blank');

        
        //realiza o download mas não abre outra aba
        // var downloadLink = document.createElement("a");
        // downloadLink.href = url;
        // downloadLink.download = fileName;
        // downloadLink.target = "_blank";

        // document.body.appendChild(downloadLink);
        // downloadLink.click();
        // document.body.removeChild(downloadLink);
        //realiza o download mas não abre outra aba
  
        // return url;
    }
  
    function geraBlob(base64str, fileName = "export-pdf.pdf"){
        // base64 string
        // decode base64 string, remove space for IE compatibility
        let binary = atob(base64str.replace(/\s/g, ''));
        let len = binary.length;
        let buffer = new ArrayBuffer(len);
        let view = new Uint8Array(buffer);
        for (let i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }
  
        // create the blob object with content-type "application/pdf"       
        let blob = new Blob( [view], { type: "application/pdf;charset=utf-8;" });

        if(isChrome() && isIos()){
            let url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.click();
            
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
        } else {
            FileSaver.saveAs(blob, fileName);
        }
  
        // return url;
    }

    function isChrome (){
        return window.navigator.userAgent.indexOf('Chrome') > -1
    }

    function isIos () {
        return /(iPad|iPhone|iPod).*WebKit/.test(window.navigator.userAgent)
    }

    return { exportBlob, geraBlob ,viewPdfNewTab };
}