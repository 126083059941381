import useEmitter from "@/composables/Emmiter"
import { Ref } from "vue"
import { UserPendingDataModalNotificatioType } from "@/layout/UserPendingDataModal/composables/types"
import moment from "moment"

const ULID = '01JETRQ6W71WPNWGAHECG8ZYRB'

const SET_NOTIFICATION = `${ULID}:SET-NOTIFICATION`
const UNSET_NOTIFICATION = `${ULID}:UNSET-NOTIFICATION`
const LOCALSTORAGE_ACTIVATION_DATE_KEY = `${ULID}:A_D_K`

function load() {
  window.localStorage.setItem(LOCALSTORAGE_ACTIVATION_DATE_KEY, moment().subtract(1, 'day').valueOf().toString())
}

function setActivationTime(amount: moment.DurationInputArg1, unit: moment.DurationInputArg2) {
  window.localStorage.setItem(LOCALSTORAGE_ACTIVATION_DATE_KEY, moment().add(amount, unit).valueOf().toString())
}

function handlePendingDataNotificationToNotificationRef(notificationRef: Ref<any | UserPendingDataModalNotificatioType[]>) {
  const emitter = useEmitter()

  emitter.on(SET_NOTIFICATION, (value: UserPendingDataModalNotificatioType) => {
    if(Array.isArray(notificationRef.value)) {
      if (!(notificationRef.value.some((notification: any | UserPendingDataModalNotificatioType) => notification?.id === SET_NOTIFICATION)))
        notificationRef.value.push({ ...value })
    } else {
      notificationRef.value = [{ ...value }]
    }
  })

  emitter.on(UNSET_NOTIFICATION, (value: UserPendingDataModalNotificatioType) => {
    if(Array.isArray(notificationRef.value)) {
      notificationRef.value = notificationRef.value.filter((notification: any | UserPendingDataModalNotificatioType) => {
        if(!notification?.id)
          return notification
        else
          return notification.id ? notification.id !== value?.id : false
      })
    }
  })
}

export default function useUserPendingDataModal () {
  return {
    LOCALSTORAGE_ACTIVATION_DATE_KEY,
    SET_NOTIFICATION,
    UNSET_NOTIFICATION,
    load,
    setActivationTime,
    handlePendingDataNotificationToNotificationRef
  }
}
