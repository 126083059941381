import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import store from "@/store/index";
import moment from "moment";
import { Actions } from "@/store/enums/StoreEnums";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;


  /**
   * @description decode jwt para pegar os valores do token
   */
  public static parseJwt (token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

  /**
   * @description valida se o token salvo ja expirou, se expirou tenta logar novamente
   */
  public static async valideTokenTime () : Promise<boolean> {

    let auth = false;
    let token = JwtService.getToken();
    if (token) {
      let tokenDecode = this.parseJwt(token);
      let time = moment().unix();
      if(time > tokenDecode.exp)
      {
        auth = await store.dispatch(Actions.LOGADO);
      }
      else
      {
        auth = true;
      }
    }

    return auth;
    
  };


  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {

    const host_api = store.getters.layoutConfig("distribuidor.dominio_api");
    const host_http = store.getters.layoutConfig("distribuidor.dominio_gestao") == 'localhost:8080' ? 'http' : 'https';
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = host_http+"://"+host_api+"/api/";
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  }

  /**
 * @description set the POST HTTP request
 * @param resource: string
 * @param params: AxiosRequestConfig
 * @returns Promise<AxiosResponse>
 */

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params).catch((error) => {
      throw new Error(`[CAMP] ApiService ${error}`);
    });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static async get(resource: string, slug = "" as string, valid = true, apiIntegracao = false): Promise<AxiosResponse> {

    const host_api = store.getters.layoutConfig("distribuidor.dominio_api");
    const host_http = store.getters.layoutConfig("distribuidor.dominio_gestao") == 'localhost:8080' ? 'http' : 'https';
    // const host_http = 'https';
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = host_http+"://"+host_api+"/api/";

    this.setHeader();
    if(valid)
    {
      await this.valideTokenTime();
    }
    
    return ApiService.vueInstance.axios
    .get(`${resource}`)
    .catch((error) => {
      throw new Error(`ErrorPath: ${resource}/${slug} ;[KT] ApiService ${error}`);
    });

  }

  public static async getIntegracao(resource: string, slug = "" as string, valid = true): Promise<AxiosResponse> {
    
    const host_api = store.getters.layoutConfig("distribuidor.dominio_integracao");
    const host_http = store.getters.layoutConfig("distribuidor.dominio_gestao") == 'localhost:8080' ? 'http' : 'https';
    ApiService.vueInstance.axios.defaults.baseURL = host_api
    ApiService.vueInstance.axios.defaults.baseURL = host_http+"://"+host_api+"/api/";
   
    this.setHeader();
    if(valid)
    {
      await this.valideTokenTime();
    }
    
    return ApiService.vueInstance.axios
    .get(`${resource}`)
    .catch((error) => {
      throw new Error(`ErrorPath: ${resource}/${slug} ;[KT] ApiService ${error}`);
    });

  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @param additionalHeaders: object
   * @returns Promise<AxiosResponse>
   */
  public static async postIntegracao(
    resource: string,
    params: AxiosRequestConfig,
    additionalHeaders = {},
    valid = true 
  ): Promise<AxiosResponse> {

    const host_api = store.getters.layoutConfig("distribuidor.dominio_integracao");
    const host_http = store.getters.layoutConfig("distribuidor.dominio_gestao") == 'localhost:8080' ? 'http' : 'https';
    ApiService.vueInstance.axios.defaults.baseURL = host_api
    ApiService.vueInstance.axios.defaults.baseURL = host_http+"://"+host_api+"/api/";

    // Configure o cabeçalho Authorization
    ApiService.vueInstance.axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
   
    Object.assign(ApiService.vueInstance.axios.defaults.headers.common, additionalHeaders);
    this.setHeader();
    
    if(valid)
    {
      await this.valideTokenTime();
    }
    
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static async post(
    resource: string,
    params: AxiosRequestConfig,
    valid = true 
  ): Promise<AxiosResponse> {

    const host_api = store.getters.layoutConfig("distribuidor.dominio_api");
    const host_http = store.getters.layoutConfig("distribuidor.dominio_gestao") == 'localhost:8080' ? 'http' : 'https';
    
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = host_http+"://"+host_api+"/api/";
    this.setHeader();
    if(valid)
    {
      await this.valideTokenTime();
    }
    
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    this.setHeader();
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static async put(
    resource: string,
    params: AxiosRequestConfig,
    valid = true 
  ): Promise<AxiosResponse> {
    this.setHeader();
    if(valid)
    {
      await this.valideTokenTime();
    }

    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }

  public static async postXlsx(
    resource: string,
    params: AxiosRequestConfig = {},
    valid = true
  ): Promise<AxiosResponse> {
    const headers = {
      Authorization: `Bearer ${JwtService.getToken()}`,
      Accept: 'application/octet-stream'
    };
    const options: AxiosRequestConfig = {
      headers,
      responseType: 'blob'
    };
  
    ApiService.setHeader();
    if (valid) {
      await ApiService.valideTokenTime();
    }
  
    return ApiService.vueInstance.axios.post(resource, params, options);
  }

  /**
   * Faz uma requisição GET para obter um arquivo XLSX como um Blob.
   *
   * @param {string} resource - O recurso (URL) para o qual a requisição será feita.
   * @param {boolean} [valid=true] - Indica se o token deve ser validado antes de fazer a requisição.
   * @returns {Promise<Blob>} - Uma promessa que resolve com o Blob do arquivo XLSX.
   */
  public static async getXlsx(
    resource: string,
    valid = true
  ): Promise<Blob> {
    const headers = {
      Authorization: `Bearer ${JwtService.getToken()}`,
      Accept: 'application/octet-stream',
    };
    const options: AxiosRequestConfig = {
      headers,
      responseType: 'blob',
    };

    ApiService.setHeader();
    if (valid) {
      await ApiService.valideTokenTime();
    }

    const response = await ApiService.vueInstance.axios.get(resource, options);
    return response.data;
  }
}

export default ApiService;
